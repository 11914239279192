@use "styles/variables/sizings" as sizings;

.vm_LoginLayout {
    flex-grow: 1;
}

.vm_Header.vm_Header-transparent {
    background-color: transparent;
    border: none;
}
