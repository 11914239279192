@use "styles/variables/borders" as borders;
@use "styles/variables/spacings" as spacings;
@use "styles/variables/sizings" as sizings;
@use "styles/variables/shadows" as shadows;

.vm_CreateConferenceForm {
    width: 100%;
    border-collapse: collapse;
    display: table;

    div {
        display: table-row;
        width: auto;
        clear: both;
    }
    .react-aria-Label {
        display: table-column;
        width: 13rem;
        float: left;
        margin-bottom: spacings.$space-4;
    }

    input {
        display: table-column;
        width: 12rem;
        float: left;
        border-color: var(--color-border);
    }

    .react-aria-Button {
        display: table-column;
        width: 12.4rem;
        float: left;
        background-color: var(--color-background-light);
        border: 1px solid var(--color-borderstrong);
        border-radius: borders.$border-radius-s;
        height: 1.5rem;
    }
}

.react-aria-Popover[data-trigger="Select"] {
    min-width: var(--trigger-width);

    .react-aria-ListBox {
        list-style: none;
        padding-left: spacings.$space-0;
        display: block;
        width: unset;
        max-height: inherit;
        min-height: unset;
        border: none;
        background-color: var(--color-background-light);

        .react-aria-Header {
            padding-left: 1.571rem;
        }
    }

    .react-aria-ListBoxItem {
        border-radius: borders.$border-radius;
        padding: spacings.$space-4;

        &[data-focus-visible] {
            outline: none;
        }

        &[data-focused],
        &[data-pressed] {
            background: var(--highlight-background);
            color: var(--highlight-foreground);
        }
    }
}

.vm_AppointmentSuccess-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: sizings.$size-relative-full;
    height: sizings.$size-relative-full;
    overflow: auto;
    background-color: var(--color-overlay-background);
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: borders.$border-radius;
}

.vm_AppointmentSuccess-dialog {
    outline: none;
    padding: spacings.$space-6;
    position: absolute;
    background-color: var(--color-background);
    border-radius: borders.$border-radius;
}

.vm_dropdowndlg {
    padding: spacings.$space-6;
    border-color: var(--color-borderstrong);
    border: 1px solid var(--color-borderstrong);
    border-radius: borders.$border-radius;
    background-color: var(--color-overlay-background);
}

.react-aria-TextField {
    display: flex;
    flex-direction: column;
    width: fit-content;
    color: var(--text-color);

    .react-aria-Input,
    .react-aria-TextArea {
        padding: 0.286rem;
        margin: 0;
        border: 1px solid var(--color-borderstrong);
        border-radius: 6px;
        background: var(--field-background);
        color: var(--field-text-color);

        &[data-focused] {
            outline: 2px solid var(--focus-ring-color);
            outline-offset: -1px;
        }
    }
}
