@use "styles/variables/shadows" as shadows;
@use "styles/variables/borders" as borders;
@use "styles/variables/spacings" as spacings;

.vm_Card {
    background-color: var(--color-background-light);
    border-radius: borders.$border-radius;
    box-shadow: shadows.$shadow-default;
    padding: spacings.$space-3;
    border: 1px solid transparent;
}
