@use "styles/variables/spacings" as spacings;
@use "styles/variables/sizings" as sizings;

.vm_MainCard {
    margin: spacings.$space-6 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: sizings.$size-relative-full;
    max-width: sizings.$default-size * 30;
    gap: spacings.$space-4;
}

.vm_MainCard-image {
    margin-top: spacings.$space-p-1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vm_MainCard-logo {
    width: sizings.$size-9;
    height: sizings.$size-9;
}

.vm_MainCard-heading {
    text-align: center;
}
