@use "styles/variables/borders" as borders;
@use "styles/variables/spacings" as spacings;
@use "styles/variables/sizings" as sizings;
@import "styles/focus";

.vm_CustomLink {
    text-decoration: none;
    border-radius: borders.$border-radius;
    border: borders.$border-size-1 solid transparent;
    outline: none;
    cursor: pointer;
    color: var(--color-primary);

    &[data-hovered] {
        text-decoration-line: underline;
    }

    &[data-pressed] {
        color: var(--color-primary-light);
    }

    &[data-focus-visible] {
        @include focus-ring;
    }
}
