@use "styles/variables/spacings" as spacings;
@use "styles/variables/sizings" as sizings;

.vm_Footer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: spacings.$space-5;
    font-size: sizings.$size-3;
    min-height: sizings.$size-8;
}
