@use "styles/variables/spacings" as spacings;
@use "styles/variables/borders" as borders;
@use "styles/variables/sizings" as sizings;
@import "styles/focus";

.vm_NavBar {
    list-style-type: none;
    margin: 0;
    padding: 0 spacings.$space-2;
    user-select: none;
}

.vm_NavBar-item {
    padding: spacings.$space-2;
    border-radius: borders.$border-radius-s;
    text-decoration: none;
    color: var(--color-text);
    display: block;
    margin: spacings.$space-2 auto;
    outline: none;
    white-space: nowrap;
    border: 1px solid transparent;

    &[data-focus-visible] {
        @include focus-ring;
    }

    &[data-hovered] {
        background-color: var(--color-secondary);
        color: var(--color-background);
    }

    &[data-current] {
        background-color: var(--color-secondary);
        color: var(--color-background);
    }

    &[data-pressed] {
        background-color: var(--color-primary-light);
        color: var(--color-background);
    }
}

@media (forced-colors: active) {
    .vm_NavBar-item {
        --color-primary: ButtonBorder;
    }
}
