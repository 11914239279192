@use "styles/variables/spacings" as spacings;

.vm_CreateConferencePage {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 2rem;
    background-color: var(--color-background-light);
    height: 100%;
}
