@import "styles/focus";

.vmSwitch {
    display: flex;
    list-style: none;
    background: var(--color-background-light);
    &[data-focus-visible] {
        @include focus-ring;
    }
    padding: 1rem;
}

.vmSwitch .indicator {
    border: 2px solid var(--color-border);
    background: var(--color-background);
    border-radius: 1.143rem;
    width: 2rem;
    height: 1.143rem;
    transition: all 0.2s;
    margin-right: 0.5em;
}

.vmSwitch .indicator::before {
    content: "";
    background: var(--color-background-light);
    border-radius: 16px;
    width: 0.857rem;
    height: 0.857rem;
    margin: 0.143rem;
    transition: all 0.2s;
    display: block;
}

.vmSwitch[data-pressed] .indicator {
    border-color: var(--border-color-pressed);
}

.vmSwitch[data-pressed] .indicator::before {
    background: var(--color-background);
}

.vmSwitch[data-selected] .indicator {
    border-color: var(--color-primary);
    background: var(--color-background);
}

.vmSwitch[data-selected] .indicator::before {
    background: var(--color-primary);
    transform: translate(100%);
}

.vmSwitch[data-selected][data-pressed] .indicator {
    border-color: var(--highlight-background-pressed);
    background: var(--color-background);
}

.vmSwitch[data-focus-visible] .indicator {
    outline: 2px solid var(--focus-ring-color);
    outline-offset: 2px;
}

.vmSwitch[data-disabled] {
    color: var(--text-color-disabled);
}

.vmSwitch[data-disabled] .indicator {
    border-color: var(--border-color-disabled);
}

.vmSwitch[data-disabled] .indicator::before {
    background: var(--border-color-disabled);
}
