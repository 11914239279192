/* TODO: Proper theming mechanisms */
/* TODO: This is just an example - define and implement design system */
$space-0: 0;
$space-1: 0.25rem;
$space-2: 0.5rem;
$space-3: 0.75rem;
$space-4: 1rem;
$space-5: 1.5rem;
$space-6: 2rem;
$space-p-1: 25%;
$space-p-2: 50%;
$space-p-3: 75%;
$space-p-4: 100%;
