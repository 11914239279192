@use "styles/variables/borders" as borders;
@use "styles/variables/spacings" as spacings;
@use "styles/variables/sizings" as sizings;

.vm_CookieNotice-dialog {
    outline: none;
    padding: spacings.$space-6;
    position: absolute;
    background-color: var(--color-background);
    border-radius: borders.$border-radius;
}

.vm_CookieNotice-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: sizings.$size-relative-full;
    height: sizings.$size-relative-full;
    overflow: auto;
    background-color: var(--color-overlay-background);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.vm_CookieNotice-content {
    display: flex;
    flex-direction: column;
    gap: spacings.$space-4;
}

.vm_CookieNotice-content-text-container {
    width: sizings.$size-popup-w-s;
}
