@use "styles/variables/borders" as borders;
@use "styles/variables/spacings" as spacings;
@use "styles/variables/sizings" as sizings;
@import "styles/focus";

.vm_FormButton {
    font-size: sizings.$size-4;
    line-height: sizings.$size-3;
    text-align: center;
    padding: spacings.$space-3;
    background-color: var(--color-primary);
    border-radius: borders.$border-radius-s;
    color: var(--color-background);
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;

    &[data-disabled] {
        background-color: var(--color-text-light);
    }

    &[data-pressed] {
        background-color: var(--color-primary-light);
    }

    &[data-focus-visible] {
        @include focus-ring;
    }

    margin-left: 0.12em;
}
.vm_FormButton-secondary {
    background-color: var(--color-secondary);
}
