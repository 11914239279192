@use "styles/variables/borders" as borders;

@mixin focus-ring(
    $line-width: borders.$border-size-3,
    $line-offset: borders.$border-size-2,
    $line-color: var(--color-focus)
) {
    outline: $line-width solid;
    outline-offset: $line-offset;
    outline-color: $line-color;
}
