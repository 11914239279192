@use "styles/variables/spacings" as spacings;
@use "styles/variables/sizings" as sizings;
@use "styles/variables/borders" as borders;

.vm_IndeterminateProgressBar {
    --bar-color: var(--color-primary-transparent);
    --fill-color: var(--color-primary);

    width: sizings.$size-relative-full;

    .vm_IndeterminateProgressBar-bar {
        background-color: var(--bar-color);
        height: sizings.$size-4;
        border-radius: borders.$border-radius-s;
        overflow: hidden;
    }

    .vm_IndeterminateProgressBar-fill {
        background: var(--fill-color);
        height: sizings.$size-relative-full;
        width: sizings.$size-relative-10;
        border-radius: inherit;
        animation: indeterminate 1.5s infinite ease-in-out;
    }
}

@keyframes indeterminate {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(1000%);
    }
}

@media (forced-colors: active) {
    .vm_IndeterminateProgressBar {
        --bar-color: ButtonFace;
        --fill-color: Highlight;

        .vm_IndeterminateProgressBar-bar {
            border: borders.$border-size-1 solid ButtonBorder;
            box-sizing: border-box;
        }
    }
}
