@use "styles/variables/spacings" as spacing;
@use "~inter-ui/default" with (
    $inter-font-display: swap,
    $inter-font-path: "~inter-ui/Inter (web)"
);
@use "~inter-ui/variable" with (
    $inter-font-display: swap,
    $inter-font-path: "~inter-ui/Inter (web)"
);
@import "styles/colors";
@include default.all;
@include variable.all;

html {
    font-family: Inter, Arial, Helvetica, sans-serif, system-ui;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: "Inter var", Arial, Helvetica, sans-serif, system-ui;
    }
}

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    line-height: 1.5;
    padding: spacing.$space-0 spacing.$space-0;
    color: var(--color-text);
    background-color: var(--color-background);

    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
