@use "styles/variables/spacings" as spacings;
@use "styles/variables/borders" as borders;
@use "styles/variables/sizings" as sizings;
@import "styles/focus";

.vm_TabView {
    display: flex;
    flex-direction: column;
}

.vm_TabView-TabList {
    display: flex;
    border-bottom: borders.$border-size-1 solid var(--color-background-dark);
}

.vm_TabView-Tab-Content {
    border-radius: borders.$border-radius;
    padding: spacings.$space-2;
    width: sizings.$size-relative-full;
}

.vm_TabView-Tab {
    padding: spacings.$space-4;
    cursor: pointer;
    transition: color 200ms;
    outline: none;

    &[data-hovered],
    &[data-focused] {
        color: var(--color-text-primary);
    }

    &[data-selected] {
        border-bottom: borders.$border-size-3 solid var(--color-primary);
        color: var(--color-text-primary);
    }

    &[data-focus-visible] {
        .vm_TabView-Tab-Content {
            @include focus-ring;
        }
    }
}

.vm_TabView-TabPanel-Content {
    border-radius: borders.$border-radius;
}

.vm_TabView-TabPanel {
    padding: spacings.$space-2;
    outline: none;

    &[data-focus-visible] {
        .vm_TabView-TabPanel-Content {
            @include focus-ring;
        }
    }
}

@media (forced-colors: active) {
    .vm_TabView-Tab {
        border: borders.$border-size-1 solid transparent;

        &[data-selected] {
            border-bottom: borders.$border-size-3 solid Highlight;
        }
    }
}
