@use "styles/variables/sizings" as sizings;
@use "styles/variables/borders" as borders;
@use "styles/variables/spacings" as spacings;

.vm_Header {
    background-color: var(--color-background-light);
    border-bottom: solid borders.$border-size-3 var(--color-background);
    display: flex;
    justify-content: space-between;
}

.vm_Header-image-container {
    padding: spacings.$space-2;
    display: flex;
    width: min-content;
    justify-content: center;
    align-items: center;
}

.vm_Header-logo {
    width: sizings.$size-icon-header;
    height: sizings.$size-icon-header;
}

.vm_Header-sub-branding {
    display: flex;
    align-items: center;
}

.vm_Header-sub-branding-logo {
    width: sizings.$size-7;
    height: sizings.$size-7;
}
