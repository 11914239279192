@use "variables/colors-light" as lightTheme;

@mixin generate-color-variables($palette) {
    @each $key, $color in $palette {
        --color-#{$key}: #{$color};
    }
}

body {
    @include generate-color-variables(lightTheme.$palette);
}
