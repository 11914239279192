@use "styles/variables/spacings" as spacings;
@use "styles/variables/borders" as borders;
@import "styles/focus";

.vm_AppointmentList {
    list-style: none;
    padding-left: spacings.$space-0;
    &[data-empty] {
        align-items: center;
        justify-content: center;
    }
}

.vm_AppointmentList-item {
    border-radius: borders.$border-radius;
    padding: spacings.$space-4;
    &[data-focus-visible] {
        @include focus-ring;
    }
}

.vm_AppointmentList-error-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vm_AppointmentList-error {
    background-color: var(--color-error-background);
    color: var(--color-error-text);
    padding: spacings.$space-4;
    border-radius: borders.$border-radius-s;
}

.vm_AppointmentList-buttons-container {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-around;
}
