$default-size: 1rem;
$size-0: 0px;
$size-1: 0.25rem;
$size-2: 0.5rem;
$size-3: 0.75rem;
$size-4: 1rem;
$size-5: 1.5rem;
$size-6: 2rem;
$size-7: 2.5rem;
$size-8: 5rem;
$size-9: 7.5rem;
$size-relative-10: 10%;
$size-relative-full: 100%;
$size-vw-full: 100vw;
$size-vh-full: 100vh;
$size-icon-m: 3rem;
$size-popup-w-s: 20rem;
$size-icon-header: 4rem;
