@use "styles/variables/spacings" as spacings;
@use "styles/variables/sizings" as sizings;
@use "styles/variables/borders" as borders;

.vm_CallLogListItem {
    display: grid;
    grid-template-columns: sizings.$size-icon-m minmax(25rem, 1fr) 5fr 10rem;
    gap: spacings.$space-4;
}

.vm_CallLogListItem-icon-container {
    grid-column-start: 1;
    grid-column-end: 2;
    width: sizings.$size-icon-m;
    height: sizings.$size-icon-m;
}

// this class represents a makeshift user icon and is to be removed when an icon pack is established
.vm_CallLogListItem-placeholder-icon {
    background-color: var(--color-background-dark);
    width: sizings.$size-icon-m;
    height: sizings.$size-icon-m;
    border-radius: 100%;
    display: flex;
    justify-content: center;
}

// this class represents a makeshift user icon and is to be removed when an icon pack is established
.vm_CallLogListItem-placeholder-icon-content {
    text-align: center;
    color: var(--color-background);
    font-size: 16px;
    line-height: 18px;
    margin: 0px;
    align-self: center;
}

.vm_CallLogListItem-date-container {
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.m_CallLogListItem-date-container-span {
    text-align: start;
}

.vm_CallLogListItem-info-container {
    grid-column-start: 3;
    grid-column-end: 4;
    display: flex;
    align-items: flex-start;
}
